<template>
    <LoadingComponent v-if="isLoading"/>
    <NavbarComponent/>
    <div class="main-content">
        <div class="dx-row" style="padding-left:1vw; padding-bottom: 4vh">
            <button class="footer-button-refresh" @click="() => this.submitSync()">
                <i class="dx-icon-refresh"></i>
                Sincronizează datele cu registrul eFactura
            </button>
        </div>
        <div class="dx-row">
            <DxDataGrid
                :allowColumnReordering='true'
                style="padding-left: 1vw; padding-right: 1vw;height: 70vh"
                :data-source="odataSource"
                :show-borders="true"
                ref="dataGridRef"
                :allow-column-resizing="true"
                @row-click="onRowClick"
                @cell-prepared="cellFormat"
                @content-ready="contentReady"
                :syncLookupFilterValues="false"
                :filter-sync-enabled="true"
                :remote-operations="{ paging: true, filtering: true, sorting: true, summary: true, groupPaging: true, grouping: true }">


<!--                <DxGroupPanel :visible='true' emptyPanelText='Trage aici o coloană pentru a grupa datele'/>-->
                <DxScrolling mode="virtual" row-rendering-mode="virtual"/>
                <DxPaging :page-size="40"/>
                <DxLoadPanel :enabled="true"/>
                <DxGrouping :context-menu-enabled='true'>
                    <DxTexts group-continued-message='Continuat de pe pagina anterioară'
                             group-continues-message='Continuat pe pagina urmatoare'/>

                </DxGrouping>
                <DxFilterRow :visible='true'/>
                <DxColumnChooser
                    height="340px"
                    :enabled="true"
                    mode="dragAndDrop"
                />
                <DxStateStoring :enabled="true" type="localStorage" storageKey="tabelMesaje"/>

                <DxSummary>

                    <DxTotalItem
                        column="Value"
                        summary-type="sum"
                        display-format="{0} RON"
                    />
                    <DxTotalItem
                        column="VatValue"
                        summary-type="sum"
                        display-format="{0} RON"
                    />
                    <DxTotalItem
                        column="Total"
                        summary-type="sum"
                        display-format="{0} RON"
                    />
                </DxSummary>


                <DxColumn dataField="Id" caption="ID"/>
                <DxColumn dataField="DataCreare" name="Data creare" dataType="date"
                          format="yyyy-MM-dd" :visible="false"/>
<!--                <DxColumn dataField="Cif" :visible="false" />-->
                <DxColumn dataField="IdSolicitare" caption="ID Solicitare" :visible="false"/>
                <DxColumn dataField="Detalii" :visible="false"/>
                <DxColumn dataField="Tip">
<!--                    <DxLookup-->
<!--                        :data-source="dsTip" display-expr="name" value-expr="name"/>-->
                </DxColumn>
                <DxColumn dataField="IssueDate" dataType="date"
                          format="yyyy-MM-dd" caption="Dată"/>
                <DxColumn dataField="DueDate" dataType="date"
                          format="yyyy-MM-dd" caption="Dată Scandentă"/>
                <DxColumn dataField="DocumentType" caption="Tip Document">
<!--                    <DxLookup-->
<!--                        :data-source="dsDocTip" display-expr="name" value-expr="name"/>-->
                </DxColumn>
                <DxColumn dataField="AccountancyStatus" caption="Contabilitate"/>
                <DxColumn dataField="SalesStatus" caption="Vânzări"/>
                <DxColumn dataField="CustomerName" caption="Nume client"/>
                <DxColumn dataField="CustomerTaxCode" caption="CIF client"/>
                <DxColumn dataField="CustomerLegalForm" caption="Nr. Reg. Com. client" :visible="false"/>
                <DxColumn dataField="SupplierName" caption="Nume furnizor"/>
                <DxColumn dataField="SupplierTaxCode" caption="CIF furnizor"/>
                <DxColumn dataField="SupplierLegalForm" caption="Nr. Reg. Com. furnizor" :visible="false"/>
                <DxColumn dataField="Value" caption="Valoare"/>
                <DxColumn dataField="VatValue" caption="Valoare TVA"/>
                <DxColumn dataField="Total" caption="Total"/>
                <DxColumn dataField="Observations" caption="Observații"/>
                <DxColumn dataField="Number" caption="Număr"/>
            </DxDataGrid>
        </div>

    </div>


<!--    <DxPopup-->
<!--        v-model:visible="showSyncPopup"-->
<!--        :drag-enabled="false"-->
<!--        :hide-on-outside-click="false"-->
<!--        :show-close-button="!isLoading"-->
<!--        :show-title="true"-->
<!--        :width="500"-->
<!--        :height="350"-->
<!--        container=".dx-viewport"-->
<!--        title="Parametri sincronizare"-->
<!--    >-->
<!--        <form @submit.prevent="submitSync()">-->
<!--            <div class="dx-fieldset">-->
<!--                <div class="dx-field">-->
<!--                    <div class="dx-field-label multiline-label">-->
<!--                        <span>Selecție perioadă</span>-->
<!--                        <div class="selected-days-wrapper ">-->
<!--                            <span>Zile selectate: </span>-->
<!--                            <span>{{ selectedDays }}</span>-->
<!--                        </div>-->
<!--                    </div>-->

<!--                    <div class="dx-field-value">-->
<!--                        <DxDateRangeBox-->
<!--                            v-model:value="currentValue"-->
<!--                            :disable-out-of-range-selection="true"-->
<!--                            display-format="dd-MM-yyyy"-->
<!--                            :min="min"-->
<!--                            :max="now"-->
<!--                        />-->
<!--                    </div>-->
<!--                </div>-->

<!--                <div class="dx-field">-->
<!--                    <div class="dx-field-label">Reîncarcă tabelul</div>-->
<!--                    <div class="dx-field-value">-->
<!--                        <DxCheckBox-->
<!--                            :value="refreshGrid"-->
<!--                        />-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="dx-field">-->
<!--                    <button type="submit" class="next-button" :class="{ inactive: isLoading }">-->
<!--                        <i class="dx-icon-refresh"></i>-->
<!--                        Sincronizează-->
<!--                    </button>-->
<!--                </div>-->
<!--            </div>-->
<!--        </form>-->
<!--    </DxPopup>-->


    <DxPopup
        v-model:visible="showReason"
        :drag-enabled="false"
        :hide-on-outside-click="false"
        :show-close-button="!isLoading"
        width="500"
        height="290"
        :show-title="true"
        container=".dx-viewport"
        :title="`Observații ${reasonTitle}`"
    >
        <div class="dx-row" style="padding-left:1vw; padding-bottom: 4vh">
            <input type="text" v-model="observations" placeholder="Observații" />
        </div>
        <div class="dx-row" style="padding-left:1vw; padding-bottom: 4vh">
            <button class="footer-button-refresh" @click="() => reasonContinue()">
                {{reasonButtonText}}
            </button>
        </div>
    </DxPopup>
    <DxPopup
        v-model:visible="showBillDetail"
        :drag-enabled="false"
        :hide-on-outside-click="false"
        :show-close-button="!isLoading"
        :show-title="true"
        container=".dx-viewport"
        :title="titleBillDetail"
    >
        <div class="container">
            <div class="row"  style="padding-top:3vh">
                <div class="col-1">
                    <h4 style="font-weight: bold;">Client:</h4>
                </div>
                <div class="col-5">
                    <h4 class="align-content-center">{{billDetail.CustomerName}}  -  {{billDetail.CustomerTaxCode}}</h4>
                </div>
                <div class="col-1">
                    <h4 style="font-weight: bold;">Număr:</h4>
                </div>
                <div class="col-5">
                    <h4 class="align-content-center">{{billDetail.Number}}</h4>
                </div>
            </div>
            <div class="row">
                <div class="col-1">
                    <h4 style="font-weight: bold;">Furnizor:</h4>
                </div>
                <div class="col-5">
                    <h4 class="align-content-center">{{billDetail.SupplierName}}  -  {{billDetail.SupplierTaxCode}}</h4>
                </div>
                <div class="col-1">
                    <h4 style="font-weight: bold;">Dată:</h4>
                </div>
                <div class="col-2">
                    <h4 class="align-content-center">{{billDetail.IssueDate?.toISOString().substring(0,10) ?? '-'}}</h4>
                </div>
                <div class="col-1">
                    <h4 style="font-weight: bold;">Scadență:</h4>
                </div>
                <div class="col-2">
                    <h4 class="align-content-center">{{billDetail.DueDate?.toISOString().substring(0,10) ?? '-'}}</h4>
                </div>
            </div>
            <div class="row">
                <DxDataGrid
                    :allowColumnReordering='true'
                    style="padding-left: 1vw; padding-right: 1vw; padding-top: 1vh; padding-bottom: 1vh; height: 25vh"
                    :data-source="billLines"
                    :show-borders="true"
                    ref="dataGridLinesRef"
                    :allow-column-resizing="true"
                    @cell-prepared="cellFormatDetails"
                >
                    <DxFilterRow :visible='true'/>
<!--                    <DxColumn dataField="id" caption="Nr. Crt."/>-->
                    <DxColumn dataField="name" caption="Nume"/>
                    <DxColumn dataField="description" caption="Descriere"/>
                    <DxColumn dataField="quantity" caption="Cantitate"/>
                    <DxColumn dataField="price" caption="Preț"/>
                    <DxColumn dataField="vatPercent" caption="TVA"/>
                    <DxColumn dataField="value" caption="Valoare"/>
                    <DxColumn dataField="vatValue" caption="Valoare TVA"/>
                    <DxScrolling mode="infinite"/>

                </DxDataGrid>

            </div>

            <div class="row">
                <div class="col-2"><h4 style="font-weight: bold"> Valoare: </h4></div>
                <div class="col-2"><h4>{{billDetail.Value}} {{billDetail.Currency}}</h4></div>

            </div>

            <div class="row">
                <div class="col-2"><h4 style="font-weight: bold"> Valoare TVA: </h4></div>
                <div class="col-2"><h4>{{billDetail.VatValue}} {{billDetail.Currency}}</h4></div>

            </div>

            <div class="row">
                <div class="col-2"><h4 style="font-weight: bold"> Total: </h4></div>
                <div class="col-2"><h4>{{billDetail.Total}} {{billDetail.Currency}}</h4></div>

            </div>
            <div class="row">

                <div  class="col-5 rounded-div">
                    <label for="accountancy" style="font-weight: bold; padding-bottom: 15px;">Opțiuni contabilitate:</label>
                    <div id="accountancy" class="row" style="padding-bottom: 15px;">
                        <div class="col-6">
                            <button
                                class="red-button"
                                :class="{ inactive: isLoading || billDetail.AccountancyStatus !== 'În așteptare' || role === 'SALES'}"
                                @click="openAccountancyReason(false)">
                                <a style="color: white" class="dx-icon-remove"></a>Respingere
                            </button>
                        </div>
                        <div class="col-6">
                            <button
                                class="next-button"
                                :class="{ inactive: isLoading || billDetail.AccountancyStatus !== 'În așteptare' || role === 'SALES'}"
                                @click="openAccountancyReason(true)">
                                <a style="color: white" class="dx-icon-check"></a>Aprobare
                            </button>
                        </div>
                    </div>

                </div>
                <div class="col-2"></div>

                <div id="sales" class="col-5 ">

                    <div class="row rounded-div">
                        <label for="sales" style="font-weight: bold;padding-bottom: 15px;">Opțiuni vânzări:</label>
                        <div class="col-6" style="padding-bottom: 15px;">
                            <button
                                class="red-button"
                                :class="{ inactive: isLoading || billDetail.SalesStatus !== 'În așteptare' || role === 'ACCOUNTANCY'}"
                                @click="openSalesReason(false)">
                                <a style="color: white" class="dx-icon-remove"></a>Respingere
                            </button>

                        </div>
                        <div class="col-6" style="padding-bottom: 15px;">
                            <button
                                class="next-button"
                                :class="{ inactive: isLoading || billDetail.SalesStatus !== 'În așteptare' || role === 'ACCOUNTANCY'}"
                                @click="openSalesReason(true)">
                                <a style="color: white" class="dx-icon-check"></a>Aprobare
                            </button>
                        </div>

                    </div>
                </div>

            </div>
            <div class="row" style="padding: 10px"></div>

            <div id="down" class="row rounded-div">
                <label for="down" style="font-weight: bold;padding-bottom: 15px;">Opțiuni descărcare:</label>
                <div class="col-2" style="padding-bottom: 15px;">
                    <button
                        class="footer-button-refresh"
                        :class="{ inactive: isLoading }"
                        @click="downloadXml()">
                        <a style="color: white" class="dx-icon-aligncenter"></a>Descărcare XML
                    </button>
                </div>
                <div class="col-2" style="padding-bottom: 15px;"></div>
                <div class="col-4" style="padding-bottom: 15px;">
                    <button
                        class="footer-button-refresh"
                        :class="{ inactive: isLoading }"
                        @click="downloadSignature()">
                        <a style="color: white" class="dx-icon-edit"></a>Descărcare Semnătură
                    </button>
                </div>
                <div class="col-2" style="padding-bottom: 15px;"></div>
                <div class="col-2" style="padding-bottom: 15px;">
                    <button
                        class="footer-button-refresh"
                        :class="{ inactive: isLoading }"
                        @click="downloadPdf()">
                        <a style="color: white" class="dx-icon-textdocument"></a>Descărcare PDF
                    </button>
                </div>
            </div>


        </div>

    </DxPopup>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import axios from "axios";
// eslint-disable-next-line no-unused-vars
import config from "@/configuration";
import NavbarComponent from "@/components/NavbarComponent";
import {
    DxHeaderFilter,
    DxSummary,
    DxTotalItem,
    DxButton,
    DxDataGrid,
    DxScrolling,
    DxPager,
    DxPaging,
    DxColumn,
    DxColumnChooser,
    DxStateStoring,
    DxFilterRow,
    DxGrouping,
    DxTexts,
    DxGroupPanel,
    DxLookup,
    DxLoadPanel
} from 'devextreme-vue/data-grid';
import { DxPopup } from 'devextreme-vue/popup';
import ODataStore from 'devextreme/data/odata/store';
import VueJwtDecode from 'vue-jwt-decode';
import LoadingComponent from "@/components/LoadingComponent";
import DataSource from "devextreme/data/data_source";


export default {
    name: "DashboardComponent.cs",
    components: {
        DxLoadPanel,
        // eslint-disable-next-line vue/no-unused-components
        DxHeaderFilter,
        // eslint-disable-next-line vue/no-unused-components
        DxLookup,
        LoadingComponent,
        NavbarComponent,
        DxDataGrid,
        DxScrolling,
        // eslint-disable-next-line vue/no-unused-components
        DxPager,
        // eslint-disable-next-line vue/no-unused-components
        DxPaging,
        DxPopup,
        DxColumn,
        DxColumnChooser,
        // eslint-disable-next-line vue/no-unused-components
        DxStateStoring,
        DxFilterRow,
        DxGrouping,
        DxTexts,
        // eslint-disable-next-line vue/no-unused-components
        DxGroupPanel,
        // eslint-disable-next-line vue/no-unused-components
        DxButton,
        // eslint-disable-next-line vue/no-unused-components
        DxSummary,
        // eslint-disable-next-line vue/no-unused-components
        DxTotalItem,
    },
    data() {
        return {
            showSyncPopup: false,
            isLoading: false,
            msInDay: 1000 * 60 * 60 * 24,
            currentValue: [
                new Date(new Date().getTime()),
                new Date(new Date().getTime())
            ],
            now: new Date(),
            refreshGrid: true,
            firm: null,
            cert: null,
            odataSource: {},
            token: null,
            min: null,
            dataGridRef: null,
            showBillDetail: false,
            titleBillDetail: "",
            billDetail: {},
            billLines: [],
            role: "",
            reasonTitle: "",
            showReason: false,
            reasonButtonText: "",
            reasonContinue: () => {},
            observations: '',
            dueDate: '',
            dsTip: [],
            dsDocTip: []
        }
    },
     async mounted() {
         await this.init();
     },
    methods: {
        contentReady(e) {
            console.log(e);
            let fDx = this.$refs["dataGridRef"].instance.getCombinedFilter();
            console.log(this.processFilter(fDx, 0));
        },
        processFilter(filter, index) {
            let result = '';
            if (filter instanceof Date) {
                result = ' ' + filter.toISOString() + ' '
            } else if (typeof filter === "string" || typeof filter === "number") {
                switch (filter) {
                    case '=':
                        result = 'eq';
                        break;
                    case '!=':
                        result = 'ne';
                        break;
                    case '>':
                        result = 'gt';
                        break;
                    case '<':
                        result = 'lt';
                        break;
                    case '>=':
                        result = 'ge';
                        break;
                    case '<=':
                        result = 'le';
                        break;
                    case 'contains':
                        result = 'contains'; // For string contains
                        break;
                    // Add other operators as needed
                    default:
                        result = ' ' + (index === 2 ? "'" : "") + filter + (index === 2 ? "'" : "") + ' '; // Default to the operator if not found
                }
            } else if (Array.isArray(filter)) {
                for (let i = 0; i < filter.length; i++) {
                    result += this.processFilter(filter[i], i);
                }

                result = '(' + result + ')';
            }

            return result;
        },
        async init() {
            this.token = localStorage.getItem("Token");
            this.role = VueJwtDecode.decode(this.token).Role;
            this.min = new Date(new Date().getTime() - 7 * this.msInDay)
            this.currentValue = [this.min, this.now];
            if (localStorage.getItem("Firm") === null)
            {
                this.$router.push("/SelectTokenAndFirm");
            }
            this.firm = JSON.parse(localStorage.getItem("Firm"));
            //this.cert = JSON.parse(localStorage.getItem("Cert"));

            this.odataSource = new DataSource({
                store: new ODataStore({

                    url: `${config.API_URL}/v1/eFacturaMessages`,
                    key: 'Id',
                    keyType: 'Edm.Int64',
                    version: 4,
                    fieldTypes: {
                        Id: "Int64",
                        IdSolicitare: "Int64"
                    },
                    beforeSend(request) {
                        request.headers['Authorization'] = `Bearer ${localStorage.getItem("Token")}`;
                    },
                }),
                filter: ["Cif", "=", this.firm.cif]
            });


            try {
                let result = await axios.get(`${config.API_URL}/StaticData/GetDocumentTypes`);
                this.dsDocTip = result.data;
                result = await axios.get(`${config.API_URL}/StaticData/GetTypes`);
                this.dsTip = result.data;
            } catch (error){
                alert("Eroare la aducerea tipurilor de documente/mesage!");
            }




        },
        async downloadPdf() {
            try {

                this.isLoading = true;
                let response = await axios.get(`${config.API_URL}/Data/DownloadPdf/${this.billDetail.Id}/`,
                    {
                        headers : {
                            'Authorization': `Bearer ${this.token}`
                        },
                        responseType: "blob"
                    });
                let url = window.URL.createObjectURL(new Blob([response.data]));
                let link = document.createElement('a');
                link.href = url;
                let fileName = response.headers['content-disposition']?.split('filename=')[1].split(';')[0] || 'downloaded-file.pdf';
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);

            }
            catch (error) {
                if (error?.response?.status === 401) {
                    alert("Autentificarea a expirat!");
                    this.$router.push('/Login');
                } else {
                    let message = await error?.response?.data.text();
                    alert(message);
                }
            } finally {
                this.isLoading = false;
            }
        },
        async downloadSignature() {
            try {

                this.isLoading = true;
                let response = await axios.get(`${config.API_URL}/Data/DownloadSignature/${this.billDetail.Id}`,
                    {
                        headers : {
                            'Authorization': `Bearer ${this.token}`
                        },
                        responseType: "blob"
                    });
                let url = window.URL.createObjectURL(new Blob([response.data]));
                let link = document.createElement('a');
                link.href = url;
                let fileName = response.headers['content-disposition']?.split('filename=')[1].split(';')[0] || 'downloaded-signature.xml';
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);

            }
            catch (error) {
                if (error?.response?.status === 401) {
                    alert("Autentificarea a expirat!");
                    this.$router.push('/Login');
                } else {
                    let message = await error?.response?.data.text();
                    alert(message);
                }
            } finally {
                this.isLoading = false;
            }
        },
        async downloadXml() {
            try {

                this.isLoading = true;
                let response = await axios.get(`${config.API_URL}/Data/DownloadXml/${this.billDetail.Id}`,
                    {
                        headers : {
                            'Authorization': `Bearer ${this.token}`
                        },
                        responseType: "blob"
                    });
                let url = window.URL.createObjectURL(new Blob([response.data]));
                let link = document.createElement('a');
                link.href = url;
                let fileName = response.headers['content-disposition']?.split('filename=')[1].split(';')[0] || 'downloaded-file.xml';
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);

            }
            catch (error) {
                if (error?.response?.status === 401) {
                    alert("Autentificarea a expirat!");
                    this.$router.push('/Login');
                } else {
                    let message = await error?.response?.data.text();
                    alert(message);
                }
            } finally {
                this.isLoading = false;
            }
        },
        async openAccountancyReason(approve) {
          this.reasonTitle = approve ? "aprobare contabilitate" : "respingere contabilitate";
          this.reasonButtonText = approve ? "Aprobă" : "Respinge";
          this.showReason = true;
          this.observations = this.billDetail.Observations;
          this.reasonContinue = () => { this.approveOrDenyAccountancy(approve, this.observations)}
        },
        async openSalesReason(approve) {
            this.reasonTitle = approve ? "aprobare vânzări" : "respingere vânzări";
            this.reasonButtonText = approve ? "Aprobă" : "Respinge";
            this.showReason = true;
            this.observations = this.billDetail.Observations;
            this.reasonContinue = () => { this.approveOrDenySales(approve, this.observations)}
        },
        async approveOrDenyAccountancy(approve, obs) {
            try {

                this.isLoading = true;
                await axios.get(`${config.API_URL}/Data/ApproveOrDenyAccountancy/${this.billDetail.Id}`,
                    {
                        headers : {
                            'Authorization': `Bearer ${this.token}`
                        },
                        params : {
                            approved: approve,
                            observations: obs
                        }
                    });
                this.billDetail.AccountancyStatus = approve ? "Aprobată" : "Respinsă";
                this.billDetail.Observations = obs;
                this.showReason = false;
                if (this.refreshGrid) {

                    await this.$refs["dataGridRef"].instance.refresh();
                }

            }
            catch (error) {
                if (error?.response?.status === 401) {
                    alert("Autentificarea a expirat!");
                    this.$router.push('/Login');
                } else {
                    alert("Eroare la schimbarea stării!");
                }
            } finally {
                this.isLoading = false;
            }
        },
        async approveOrDenySales(approve, obs) {
            try {

                this.isLoading = true;
                await axios.get(`${config.API_URL}/Data/ApproveOrDenySales/${this.billDetail.Id}`,
                    {
                        headers : {
                            'Authorization': `Bearer ${this.token}`
                        },
                        params : {
                            approved: approve,
                            observations: obs
                        }
                    });
                this.billDetail.SalesStatus = approve ? "Aprobată" : "Respinsă";
                this.billDetail.Observations = obs;
                if (this.refreshGrid) {

                    await this.$refs["dataGridRef"].instance.refresh();
                }
                this.showReason = false;
            }
            catch (error) {
                if (error?.response?.status === 401) {
                    alert("Autentificarea a expirat!");
                    this.$router.push('/Login');
                } else {
                    alert("Eroare la schimbarea stării!");
                }

            } finally {
                this.isLoading = false;
            }
        },
        convertRangeToDays([startDate, endDate]) {
            //console.log(startDate, endDate);
            let daysCount = 0;

            if (startDate && endDate) {
                daysCount = Math.floor(Math.abs((endDate - startDate) / this.msInDay)) + 1;
            }
            return daysCount;
        },
        async submitSync() {

            try {
                let intStart = new Date(new Date().getTime() - 58 * this.msInDay);
                let intEnd = new Date();
                this.isLoading = true;
                await axios.get(`${config.API_URL}/Data/SyncMessageList/${this.firm.id}`,
                    {
                        headers : {
                            'Authorization': `Bearer ${this.token}`
                        },
                        params : {
                            intStart: intStart.valueOf(),
                            intEnd:  intEnd.valueOf()
                        }
                    });
                await this.$refs["dataGridRef"].instance.refresh();


            }
            catch (error) {
                if (error?.response?.status === 401) {
                    alert("Autentificarea a expirat!");
                    this.$router.push('/Login');
                } else {
                    alert("Eroare la sincronizare!");
                }
            } finally {
                this.isLoading = false;
            }

        },
        async onRowClick(e) {
            if (e.rowType === 'totalFooter' || e.data?.Tip === 'ERORI FACTURA' || e.data?.Tip === 'MESAJ CUMPARATOR TRANSMIS') return;
            this.titleBillDetail = `Factura ${e.data.Number} din data de ${e.data.IssueDate.toISOString().substring(0, 10)}`;
            this.billDetail = e.data;
            this.dueDate = this.billDetail.DueDate;
            try {

                this.isLoading = true;
                let result = await axios.get(`${config.API_URL}/Data/GetMessageDetails/${e.data.Id}`,
                    {
                        headers : {
                            'Authorization': `Bearer ${this.token}`
                        },
                    });
                this.showBillDetail = true;
                this.billLines = result.data;
            }
            catch (error) {
                if (error?.response?.status === 401) {
                    alert("Autentificarea a expirat!");
                    this.$router.push('/Login');
                } else {
                    alert(error.response.data);
                }
            } finally {
                this.isLoading = false;
            }
        },
        cellFormatDetails(e) {
            if (e.rowType !== 'data')
            {
                return;
            }

            if (e.column.dataField === 'quantity') {
                let suffix = e.data.quantityMeasurment;
                e.cellElement.innerHTML = `${e.value.toLocaleString('en-US')} ${suffix}`;
            }

            if (e.column.dataField === 'vatPercent') {
                let suffix = '%';
                e.cellElement.innerHTML = `${e.value}${suffix}`;
            }

            if (e.column.dataField === 'price' ||
                e.column.dataField === 'value' ||
                e.column.dataField === 'vatValue') {
                let suffix = e.data.currency;
                e.cellElement.innerHTML = `${e.value.toLocaleString('en-US')} ${suffix}`;
            }
        },
        cellFormat(e) {
            if (e.rowType !== 'data') return;

            if (e.column.dataField === 'Total' ||
                e.column.dataField === 'VatValue' ||
                e.column.dataField === 'Value') {
                let suffix = e.data.Currency;
                e.cellElement.innerHTML = `${(e.value ?? '').toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                })} ${suffix ?? ''}`;
            }
            if (e.column.dataField === 'AccountancyStatus' ||
                e.column.dataField === 'SalesStatus') {
                if (e.data?.Tip === 'ERORI FACTURA' || e.data?.Tip === 'MESAJ CUMPARATOR TRANSMIS') {
                    e.cellElement.innerHTML = ``;
                    e.cellElement.style.cssText = "";
                } else {
                    if (e.value === 'În așteptare') {
                        e.cellElement.innerHTML = `<a style="color: white" class="dx-icon-clock"></a> ${e.value}`
                        e.cellElement.style.cssText = "color: white; background-color: CornflowerBlue"
                    } else if (e.value === 'Aprobată') {
                        e.cellElement.innerHTML = `<a style="color: white" class="dx-icon-check"></a> ${e.value}`
                        e.cellElement.style.cssText = "color: white; background-color: LimeGreen"
                    } else if (e.value === 'Respinsă') {
                        e.cellElement.innerHTML = `<a style="color: white" class="dx-icon-remove"></a> ${e.value}`
                        e.cellElement.style.cssText = "color: white; background-color: Tomato"
                    }
                }


            }

        },
        customizeValueSummary(e) {

            return `${e.value.toLocaleString('en-US')} RON`
        },
        customizeVatValueSummary(e) {

            return `${e.value.toLocaleString('en-US')} RON`
        },
        customizeTotalValueSummary(e) {

            return `${e.value.toLocaleString('en-US')} RON`
        }
    },
    computed: {
        selectedDays() {
            return this.convertRangeToDays(this.currentValue);
        }
    }
}
</script>

<style scoped>


.inactive {
    opacity: 0.5; /* Makes it look inactive */
    pointer-events: none; /* Disables all interactions */
}
.main-content {
    padding-top: 13vh
}
.footer-button-refresh {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #007BFF;
    color: white;
    cursor: pointer;
    font-weight: bold;
}

.footer-button-refresh:hover {
    background-color: #0056b3;
}
.form-group {
    margin-bottom: 20px; /* Increased margin to create more space between form elements */
    text-align: left;
}

.next-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #28a745; /* Green background color */
    color: white;
    cursor: pointer;
    font-weight: bold;
}

.next-button:hover {
    background-color: #218838; /* Darker green for hover effect */
}
.red-cell {
    background-color: #dc3545;
}

.green-cell {
    background-color: #28a745; /* Green background color */
}

.blue-cell {
    background-color: #007BFF;
}

.red-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #dc3545; /* Red background color */
    color: white;
    cursor: pointer;
    font-weight: bold;
}

.red-button:hover {
    background-color: #c82333; /* Darker red for hover effect */
}

.dx-field {
    padding: 8px;
}

.selected-days-wrapper {
    font-size: 12px;
    opacity: 0.5;
}

.multiline-label {
    padding-top: 0;
}

input[type="text"] {
    width: 100%;
    padding: 12px; /* Increased padding inside input fields */
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
}

input[type="text"]:focus {
    border-color: #007BFF;
    outline: none;
}

.rounded-div {
    border: 1px solid black;  /*Adds a border */
    border-radius: 10px; /* Rounds the corners */
    padding: 10px; /* Adds some padding inside the div */
    text-align: center; /* Centers the text inside */
}

</style>